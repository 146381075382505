import { createApp } from 'vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import Store from './store';
import Toast, { POSITION } from "vue-toastification";
import { defineRule } from 'vee-validate';
import * as AllRules from '@vee-validate/rules'; // working in zuludevs
// import AllRules from '@vee-validate/rules'; // working in zlustage
import vSelect from "vue-select";
import VPagination from "@hennge/vue3-pagination";
// import Datepicker from 'vue3-date-time-picker';
import VueFeather from 'vue-feather';
import { createI18n } from 'vue3-i18n';
import staticVars from './staticVars';
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import VueClipboard from 'vue3-clipboard';
import VueFloatLabel from 'vue-float-label';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VOtpInput from "vue3-otp-input";
import GoogleSignInPlugin from "vue3-google-signin";
import * as COMMON_METHODS from './commonFunctions';
import Vue3Tour from 'vue3-tour';
import '../public/assets/css/font-awesome.min.css';
import '../public/assets/css/bootstrap.min.css';
import '../public/assets/css/style.css';
import '../public/assets/css/responsive.css';
// import '../public/assets/js/popper.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import 'vue3-carousel/dist/carousel.css';
import 'vue3-tour/dist/vue3-tour.css';
import * as am5 from "@amcharts/amcharts5";
import Nodata from './components/shared/no-data.vue';
import AppLoader from '@/components/shared/AppLoader.vue';
import Loader from '@/components/shared/loader.vue';
import Spinner from '@/components/shared/loader-load.vue';
import loginPopup from './components/shared/loginPopup.vue';
import Slider from '@vueform/slider';
import "@vueform/slider/themes/default.css";
import Logo from "@/components/shared/logo.vue";
import VLazyImage from "v-lazy-image";
//Locales
import en from '../public/assets/locales/en.js';
//Internationalization
var i18n = createI18n({
    locale: 'en',
    messages: {
        en: en,
    },
});
Object.keys(AllRules).forEach(function (rule) {
    defineRule(rule, AllRules[rule]);
});
defineRule('required', function (value, t, ctx) {
    if (!value || (value + '').trim() == '') {
        return ctx.field + ' field is required';
    }
    return true;
});
defineRule('maxChar', function (value, t, ctx) {
    if (value && t.length > 0 && value.toString().length > t[0]) {
        return "Your message exceeds " + t[0] + " characters. Please shorten it and try again";
    }
    return true;
});
defineRule('onlyAlphabet', function (value, t, ctx) {
    var regex = /^[A-Za-z\s]*$/;
    if (regex.test(String(value))) {
        return true;
    }
    return ctx.field + ' must contain only letters.';
});
// var apm = require('elastic-apm-node').start({
//   serviceName: '',
//   secretToken: '',
//   serverUrl: 'http://182.156.197.109:8200',
//   environment: 'development'
//   })
var app = createApp(App);
var pinia = createPinia();
var filterBeforeCreate = function (toast, toasts) {
    if (toasts.filter(function (t) { return t.type === toast.type; }).length !== 0) {
        return false;
    }
    if (typeof (toast.content) !== 'string') {
        return false;
    }
    return toast;
};
// app.use(Toast, {
//   position: 'top-right',
//   timeout: 3000,
//   hideProgressBar: true,
//   type: 'success'
// });
app.use(Toast, { filterBeforeCreate: filterBeforeCreate, position: POSITION.TOP_CENTER, transition: "Vue-Toastification__fade", });
// app.use(VueMeta)
app.use(VueFloatLabel);
app.use(pinia);
am5.addLicense("" + process.env.VUE_APP_AMCHART_LICENSE_KEY);
pinia.use(piniaPluginPersistedstate);
app.use(VueTelInput);
app.use(Vue3Tour);
app.use(GoogleSignInPlugin, {
    clientId: "" + process.env.VUE_APP_GOOGLE_CLIENT_ID,
});
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});
app.config.globalProperties.static_vars = staticVars;
app.config.globalProperties.COMMON = COMMON_METHODS;
app.component('v-select', vSelect);
app.component('v-paginate', VPagination);
// app.component('date-picker', Datepicker);
app.component(VueFeather.name, VueFeather);
app.component('Nodata', Nodata);
app.component('AppLoader', AppLoader);
app.component('Loader', Loader);
app.component('Spinner', Spinner);
app.component('loginPopup', loginPopup);
app.component('v-otp-input', VOtpInput);
app.component('Slider', Slider);
app.component('v-lazy-image', VLazyImage);
app.component('VueDatePicker', VueDatePicker);
app.component('Logo', Logo);
app.use(i18n).use(Store).use(router).mount('#app');
