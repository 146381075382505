<template>
    <!-- Modal -->
    <div class="modal fade muySellModal"
        :class="[{ 'show': $parent.tradeType && Object.keys($parent.instrumentDetail).length && !orderSucess }]">
        <div class="modal-dialog modal-dialog-centered border-0">
            <div class="modal-content">
                <Loader :classname="'innerLoader'" v-if="loader"></Loader>
                <div class="modal-header bg-white border-0 pb-0">
                    <div class="d-flex align-items-center justify-content-center">
                        <div
                            class="toggleSlidebutton d-inline-flex align-items-center justify-content-center cardBorder">
                            <a href="javascript:void(0)" class="button fillBtn buy zulu_btn"
                                :class="[{ 'active': $parent.tradeType == 'buy' }]"
                                @click="$parent.tradeType = 'buy'; setPrice()">{{ $t('buysell.text14') }}</a>
                            <a href="javascript:void(0)" class="button fillBtn sell zulu_btn"
                                :class="[{ 'active': $parent.tradeType == 'sell' }]"
                                @click="$parent.tradeType = 'sell'; setPrice()">{{ $t('buysell.text15') }}</a>
                        </div>
                    </div>
                    <button type="button" class="btn-close"
                        @click="$parent.tradeType = ''; $parent.addClassInBody()"></button>
                </div>
                <div class="modal-body">
                    <div class="flex-between border-bottom py-3 mb-2">
                        <div class="d-flex align-items-center">
                            <div class="vueRisk">
                                <span class="d-flex align-items-center justify-content-center me-2">
                                    <v-lazy-image width="30" height="30" class="euCurrency"
                                        :src="static_vars.marketImageSURL + $parent.instrumentDetail.symbol.toUpperCase().replace(/\//g, '') + '.svg'"
                                        :alt="$parent.instrumentDetail.symbol"
                                        :title="$parent.instrumentDetail.symbol" />
                                </span>
                            </div>
                            <p class="medium mb-0">{{ $parent.instrumentDetail.symbol }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                            <p class="mb-0 f-15 medium me-2 ">{{ livePrice() }}</p>
                            <p class="mb-0 px-1 bold d-inline-flex"
                                :class="(parseFloat($parent.instrumentDetail.dailyPriceChangePercentage) >= 0.0) ? 'greenView' : 'redView'">
                                {{ parseFloat($parent.instrumentDetail.dailyPriceChangePercentage) >= 0.0 ? '+' :
            '' }}{{ parseFloat($parent.instrumentDetail.dailyPriceChangePercentage).toFixed(2) }}%
                            </p>
                        </div>
                    </div>
                    <div class="flex-between align-items-start border-bottom pb-2 mb-2">
                        <div class="tottalValue" v-if="Object.keys(store.userSelectedAccount).length">
                            <div class="d-flex align-items-center">
                                <v-lazy-image width="30" v-if="store.userSelectedAccount.brokerLogo"
                                    :src="'https://zulutrade.com/' + store.userSelectedAccount.brokerLogo"
                                    :alt="store.userSelectedAccount.brokerName" />
                                <p class="mb-0 f-14 ms-1">{{ store.userSelectedAccount.username }}</p>
                            </div>
                            <p class=" mb-0">{{ COMMON.getBaseCurrencySymbol() }}{{ (store.userSelectedAccount.balance ||
            0) + (store.userSelectedAccount?.credit || 0) }}</p>
                        </div>
                        <div class="position-relative w-50 text-end" v-if="accountList().length">
                            <a href="javascript:void(0)" class="f-15 mb-0 secondary"
                                @click="showAccount = !showAccount">{{ $t('buysell.text1') }}</a>
                            <ul class="dropdown_menu_animated py-2" :class="showAccount ? 'show' : ''">
                                <li :class="[{ 'disabled': list.connectivityStatus !== 'Connected' }]"
                                    v-for="list, key in accountList()" :key="key" @click="changeBroker(list)"><a
                                        class="d-flex align-items-center" href="javascript:void(0)">
                                        <v-lazy-image v-if="list.brokerLogo" width="30"
                                            :src="'https://zulutrade.com/' + list.brokerLogo" class="me-1"
                                            :alt="list.brokerLogo" />{{ list.username }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="specificPrice">
                        <div class="position-relative">
                            <a :class="buyAT ? 'active' : ''" @click.prevent="buyAT = !buyAT" href="javascript:void(0)"
                                class="flex-between bg-card dropButton">{{ buyAtvalue }}<vue-feather size="16"
                                    type="chevron-down"></vue-feather> </a>
                            <ul class="dropdown_menu_animated py-2" :class="buyAT ? 'show' : ''">
                                <li v-for="value, key in buyJson" :key="key"><a href="javascript:void(0)"
                                        @click="buyAtvalue = value; buyAT = false; setPrice()">{{ value }}</a></li>
                            </ul>
                        </div>
                        <div class="showPrice" v-if="buyAtvalue == 'Limit Order'">
                            <div class="form-group mb-1">
                                <label class="upperInput">{{ $t('buysell.text2') }}</label>
                                <div class="position-relative">
                                    <input type="number" class="form-control" v-model="specificPrice"
                                        name="specificPrice" />
                                    <a href="javascript:void(0)" class="position-absolute left pointer"
                                        @click="decreaseCount('SPECIFIC-PRICE')"><vue-feather size="22"
                                            type="minus"></vue-feather></a>
                                    <a href="javascript:void(0)" class="position-absolute right pointer"
                                        @click="increaseCount('SPECIFIC-PRICE')"><vue-feather size="22"
                                            type="plus"></vue-feather></a>
                                </div>
                            </div>
                        </div>
                        <div class="investmentAmount d-flex align-items-center justify-content-between">
                            <h6 class="mb-0">{{ $t('buysell.text3') }}</h6>
                            <div class="position-relative">
                                <a :class="investment ? 'active' : ''" @click.prevent="investment = !investment"
                                    href="javascript:void(0)"
                                    class="flex-between dropButton my-0">{{ investmentType }}<vue-feather class="ms-1"
                                        size="16" type="chevron-down"></vue-feather> </a>
                                <ul class="dropdown_menu_animated py-2" :class="investment ? 'show' : ''">
                                    <li v-for="value, key in investmentJson" :key="key"><a href="javascript:void(0)"
                                            @click="investmentType = value; investment = false">{{ value }}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="showPrice">
                            <div class="form-group position-relative mb-1 outside" v-if="investmentType == 'Lots'">
                                <label class="upperInput">{{ $t('buysell.text4') }}</label>
                                <div class="position-relative">
                                    <input type="number" class="form-control" v-model="lots" name="lots" />
                                    <a href="javascript:void(0)" class="position-absolute left pointer"
                                        @click="decreaseCount('LOTS')"><vue-feather size="22"
                                            type="minus"></vue-feather></a>
                                    <a href="javascript:void(0)" class="position-absolute right pointer"
                                        @click="increaseCount('LOTS')"><vue-feather size="22"
                                            type="plus"></vue-feather></a>
                                </div>
                            </div>
                            <div class="form-group position-relative mb-1 outside" v-if="investmentType == 'Unit'">
                                <label class="upperInput">{{ $t('buysell.text5') }}</label>
                                <div class="position-relative">
                                    <input type="number" class="form-control" v-model="amount" name="amount" />
                                    <a href="javascript:void(0)" class="position-absolute left pointer"
                                        @click="decreaseCount('UNIT')"><vue-feather size="22"
                                            type="minus"></vue-feather></a>
                                    <a href="javascript:void(0)" class="position-absolute right pointer"
                                        @click="increaseCount('UNIT')"><vue-feather size="22"
                                            type="plus"></vue-feather></a>
                                </div>
                            </div>
                            <div class="form-group position-relative mb-1 outside"
                                v-if="investmentType == COMMON.getBaseCurrencySymbol()">
                                <label class="upperInput">{{ COMMON.getBaseCurrencySymbol() }}</label>
                                <div class="position-relative">
                                    <input type="number" class="form-control" v-model="margin" name="margin" />
                                    <a href="javascript:void(0)" class="position-absolute left pointer"
                                        @click="decreaseCount('MARGIN')"><vue-feather size="18"
                                            type="minus"></vue-feather></a>
                                    <a href="javascript:void(0)" class="position-absolute right pointer"
                                        @click="increaseCount('MARGIN')"><vue-feather size="18"
                                            type="plus"></vue-feather></a>
                                </div>
                            </div>
                            <sub class="f-12 red">{{ investmentError }}</sub>
                            <div class="unit mb-4" v-if="Object.keys(store.assetTradingConvesrionData).length">
                                <ul class="flex-between flex-wrap flexList">
                                    <li v-if="investmentType != 'Unit'">
                                        <p class="f-14 mb-0"><b class="f-14">{{ store.assetTradingConvesrionData?.amount
            * updateStats() || '' }}</b> {{ $t('buysell.text6') }}</p>
                                    </li>
                                    <li>
                                        <p class="f-14 mb-0"><b class="f-14">{{
            parseFloat((store.assetTradingConvesrionData?.exposure *
                updateStats()).toFixed(2)) || '' }}</b> {{ $t('buysell.text7') }}</p>
                                    </li>
                                    <li v-if="investmentType != 'Lots'">
                                        <p class="f-14 mb-0"><b class="f-14">{{ store.assetTradingConvesrionData?.lots *
            updateStats() || '' }}</b> {{ $t('buysell.text8') }}</p>
                                    </li>
                                    <li v-if="store.assetTradingConvesrionData?.followerFinInfo">
                                        <p class="f-14 mb-0"><b class="f-14">{{
            store.assetTradingConvesrionData?.followerFinInfo.leverage || '' }}</b>
                                            {{ $t('buysell.text9') }}</p>
                                    </li>
                                    <li
                                        v-if="store.assetTradingConvesrionData?.margin && store.assetTradingConvesrionData?.availableMargin">
                                        <p class="f-14 mb-0"><b class="f-14">{{
            parseFloat((((store.assetTradingConvesrionData?.margin /
                store.assetTradingConvesrionData?.availableMargin) * 100) *
                updateStats()).toFixed(2)) }}%</b> {{ $t('buysell.text10') }}</p>
                                    </li>
                                    <li v-if="investmentType != COMMON.getBaseCurrencySymbol()">
                                        <p class="f-14 mb-0"><b class="f-14">{{
            parseFloat((store.assetTradingConvesrionData?.margin * updateStats() ||
                0).toFixed(2)) }}</b> {{ COMMON.getBaseCurrencySymbol() }}</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="stopLoss flex-between pb-2 mb-2">
                                <p class="mb-0 medium">{{ $t('buysell.text11') }}</p>
                                <label class="switch">
                                    <input type="checkbox" v-model="stopLoss" @change="changeStopLoss" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="form-group position-relative mb-1" v-if="stopLoss">
                                <label class="upperInput">{{ COMMON.getBaseCurrencySymbol() }}</label>
                                <input type="number" class="form-control" v-model="stoplossPrice"
                                    name="stoplossPrice" />
                                <!-- <input type="number" class="form-control" v-model="takeProfitPrice" name = "takeProfitPrice" v-else/> -->
                                <span class="position-absolute left pointer"
                                    @click="decreaseCount('STOPLOSS')"><vue-feather size="18"
                                        type="minus"></vue-feather></span>
                                <span class="position-absolute right pointer"
                                    @click="increaseCount('STOPLOSS')"><vue-feather size="18"
                                        type="plus"></vue-feather></span>
                            </div>
                            <div class="stopLoss flex-between mb-2">
                                <p class="mb-0 medium">{{ $t('buysell.text12') }}</p>
                                <label class="switch">
                                    <input type="checkbox" v-model="takeProfit" @change="changeTakeProfit" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="form-group position-relative mb-1" v-if="takeProfit">
                                <label class="upperInput">{{ COMMON.getBaseCurrencySymbol() }}</label>
                                <input type="number" class="form-control" v-model="takeProfitPrice"
                                    name="takeProfitPrice" />
                                <!-- <input type="number" class="form-control" v-model="stoplossPrice" name = "stoplossPrice" v-else/> -->
                                <span class="position-absolute left pointer"
                                    @click="decreaseCount('TakePROFIT')"><vue-feather size="18"
                                        type="minus"></vue-feather></span>
                                <span class="position-absolute right pointer"
                                    @click="increaseCount('TakePROFIT')"><vue-feather size="18"
                                        type="plus"></vue-feather></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footers p-3 border-0">
                    <div
                        v-if="!correctValue && store.userSelectedAccount.balance && !notSupportedCurrency && !checkMarketAvailability()">
                        <a href="javascript:void(0)" class="button d-flex bold fillBtn buyBtn zulu_btn"
                            v-if="$parent.tradeType == 'buy'"
                            :class="[{ 'disabled': !correctValue }]">{{ $t('buysell.text14') }}</a>
                        <a href="javascript:void(0)" class="button d-flex bold fillBtn sellBtn zulu_btn"
                            v-if="$parent.tradeType == 'sell'"
                            :class="[{ 'disabled': !correctValue }]">{{ $t('buysell.text15') }}</a>
                    </div>
                    <div v-else>
                        <a href="javascript:void(0)" class="button d-flex bold fillBtn sellBtn zulu_btn disabled"
                            v-if="!store.userSelectedAccount.balance">{{ $t("buysell.text18") }}</a>
                        <a href="javascript:void(0)" class="button d-flex bold fillBtn sellBtn zulu_btn disabled"
                            v-else-if="notSupportedCurrency && message && store.userSelectedAccount.balance">{{ message }}</a>
                        <a href="javascript:void(0)"
                            class="button d-flex bold fillBtn sellBtn zulu_btn disabled marketClosed"
                            v-if="checkMarketAvailability() && !notSupportedCurrency && store.userSelectedAccount.balance">{{ $t('buysell.text13') }}</a>
                        <a href="javascript:void(0)" class="button d-flex bold fillBtn buyBtn zulu_btn"
                            @click.prevent="placeOrder()"
                            v-if="$parent.tradeType == 'buy' && !checkMarketAvailability() && !notSupportedCurrency && store.userSelectedAccount.balance"
                            :class="[{ 'disabledCustom': !validSl || !validTP || !validSP || (notSupportedCurrency && !message) }, { 'disabled': store.customerDetail?.readOnly }]">{{ $t('buysell.text14') }}</a>
                        <a href="javascript:void(0)" class="button d-flex bold fillBtn sellBtn zulu_btn"
                            @click.prevent="placeOrder()"
                            v-if="$parent.tradeType == 'sell' && !checkMarketAvailability() && !notSupportedCurrency && store.userSelectedAccount.balance"
                            :class="[{ 'disabledCustom': !validSl || !validTP || !validSP || (notSupportedCurrency && !message)}, { 'disabled': store.customerDetail?.readOnly }]">{{$t('buysell.text15')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- successfully bought -->
    <div class="modal fade muySellModal" :class="[{'show' : orderSucess}]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0 pb-0">
                    <button type="button" class="btn-close"
                        @click="orderSucess = false;$parent.tradeType = ''"></button>
                </div>
                <div class="modal-body mb-5">
                    <div class="text-center mb-3">
                        <v-lazy-image src="/assets/images/success14789.png" :alt="$t('buysell.text17')" />
                        <h5>{{$t('buysell.text16')}}.</h5>
                    </div>
                    <div class="successMessage bg-card flex-between p-2 mb-2">
                        <div class="d-flex align-items-center">
                            <div class="vueRisk">
                                <span class="d-flex align-items-center justify-content-center me-3"
                                    v-if="$parent.instrumentDetail.symbol.includes('/')">
                                    <v-lazy-image width="30" height="30" class="euCurrency"
                                        :src="static_vars.marketImageSURL+$parent.instrumentDetail.symbol.toUpperCase().replace(/\//g, '')+'.svg'"
                                        :alt="$parent.instrumentDetail.symbol"
                                        :title="$parent.instrumentDetail.symbol" />
                                </span>
                            </div>
                            <p class="medium mb-0">{{$parent.instrumentDetail.symbol}}</p>
                        </div>
                        <!-- <p class="mb-0 f-18 neutralGrey medium me-1">$385</p> -->
                    </div>
                </div>
                <!-- <div class="modal-footers p-3 border-0">
                    <router-link to="/dashboard-portfolio" class="button d-flex bold fillBtn zulu_btn">Return to Dashboard</router-link>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import moment from 'moment'
import { useToast } from "vue-toastification";
// import _ from 'lodash'
import $ from "jquery";
//import PriceFeed from "@/store/stomp";
//import TradesFeed from "@/store/trades-stomp";
export default {
    setup() {
        const store = myStore();
        const toast = useToast();
        return { store, toast };
    },
    data() {
        return {
            buyAT: false,
            investment: false,
            buyJson: {
                1: "Market Order",
                2: "Limit Order",
            },
            buyAtvalue: 'Market Order',
            investmentJson: {
                1: 'Lots',
                2: 'Unit',
                3: this.COMMON.getBaseCurrencySymbol()
            },
            investmentType: 'Lots',
            lots: 0.01,
            amount: 0,
            margin: 0,
            stopLoss: false,
            takeProfit: false,
            stoplossPrice: 0,
            takeProfitPrice: 0,
            specificPrice: 0,
            orderSucess: false,
            loader: false,
            showAccount: false,
            validSl: true,
            validTP: true,
            validSP: true,
            notSupportedCurrency: false,
            message: '',
            investmentError: '',
            returnPipMultuplier: 0,
            correctValue: false,
        };
    },
    watch: {
        stoplossPrice() {
            this.stopLossWatch()
            this.limitOrderWatch()
            this.takeProfitWatch()
            this.stoplossPrice = parseFloat((this.stoplossPrice).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        takeProfitPrice() {
            this.stopLossWatch()
            this.limitOrderWatch()
            this.takeProfitWatch()
            this.takeProfitPrice = parseFloat((this.takeProfitPrice).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        specificPrice() {
            this.stopLossWatch()
            this.limitOrderWatch()
            this.takeProfitWatch()
            this.specificPrice = parseFloat((this.specificPrice).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        stopLoss() { // StopLoss condition remove if checkbox disable
            if (!this.stopLoss) { this.validSl = true; }
            if (!this.stopLoss && !this.takeProfit) { this.validSP = true; }
        },
        takeProfit() { // TakeProfit condition remove if checkbox disable
            if (!this.takeProfit) { this.validTP = true; }
            if (!this.takeProfit && !this.stopLoss) { this.validSP = true; }
        },
        lots() { // Validation in Lots
            this.orderError()
            this.updateStats()
        },
        amount() { // Validation in Units
            this.orderError()
            this.updateStats()
        },
        margin() { // Validation in Currency
            this.orderError()
            this.updateStats()
        },
    },
    props: ["currentInstrumentID"],
    methods: {
        accountList() {
            if (this.store.userTradingAccountsList.length && Object.keys(this.store.userSelectedAccount).length) {
                return this.store.userTradingAccountsList.filter(i => i.zuluAccountId != this.store.userSelectedAccount.zuluAccountId)
            } else {
                return []
            }
        },
        formValid() {
            if (this.buyAtvalue != 'Market Order') {
                if (parseFloat(this.specificPrice) > this.negSLTP() && parseFloat(this.specificPrice) < this.posSLTP()) {
                    return false
                }
            }
            if (this.stopLoss) {//stop loss cases
                if (this.stoplossPrice) {
                    if (this.buyAtvalue == 'Market Order') {
                        if (this.$parent.tradeType == 'buy' && parseFloat(this.stoplossPrice) > this.negSLTP()) {
                            return false
                        } else if (this.$parent.tradeType == 'sell' && parseFloat(this.stoplossPrice) < this.posSLTP()) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        if (this.$parent.tradeType == 'buy') {
                            if (parseFloat(this.stoplossPrice) > parseFloat(this.store.assetTradingConvesrionData?.currentPrice)) {
                                return false
                            }
                        } else if (this.$parent.tradeType == 'sell') {
                            if (parseFloat(this.stoplossPrice) < parseFloat(this.store.assetTradingConvesrionData?.currentPrice)) {
                                return false
                            }
                        } else {
                            return true
                        }
                    }
                } else {
                    return false
                }
            }
            if (this.takeProfit) {//take profit cases
                if (this.buyAtvalue == 'Market Order') {
                    if (this.$parent.tradeType == 'buy' && parseFloat(this.takeProfitPrice) < this.posSLTP()) {
                        return false
                    } else if (this.$parent.tradeType == 'sell' && parseFloat(this.takeProfitPrice) > this.negSLTP()) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    if (this.$parent.tradeType == 'buy') {
                        if (parseFloat(this.takeProfitPrice) < parseFloat(this.store.assetTradingConvesrionData?.currentPrice)) {
                            return false
                        }
                    } else if (this.$parent.tradeType == 'sell') {
                        if (parseFloat(this.takeProfitPrice) > parseFloat(this.store.assetTradingConvesrionData?.currentPrice)) {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            }
        },
        setPrice() {
            if (this.buyAtvalue == 'Limit Order') {
                if (this.$parent.tradeType == 'buy') {
                    this.specificPrice = this.limitPrice(-5)
                } else if (this.$parent.tradeType == 'sell') {
                    this.specificPrice = this.limitPrice(5)
                }
            }
            this.changeStopLoss()
            this.changeTakeProfit()
        },
        changeBroker(list) {
            if (list.connectivityStatus === 'Connected') {
                this.showAccount = false
                this.store.$patch({ 'userSelectedAccount': list })
                this.store.getUserDashboardData({}, true, '', '10000')
                this.store.getProviderOpenTrades({}, true);
                this.getAllCurrencies();
                this.callConversionStats();
                let form = {
                    'targetBrokerAccountId': list.brokerAccountId,
                    'page': 0,
                    'size': 10,
                    'sort': 'id,desc',
                };
                this.store.userNotifications(form, false)
            }
        },
        getAllCurrencies() {
            let json = {
                'from': moment().subtract(5, 'days').format('YYYY-MM-DD'),
                'to': moment().format('YYYY-MM-DD')
            }
            this.store.getMarketCaterogyWise(json, false);
        },
        checkMarketAvailability() {
            if (Object.keys(this.store.assetTradingConvesrionData).length) {
                if (this.store.assetTradingConvesrionData?.isClosed) {
                    return true
                } else {
                    return false
                }
            }
        },
        changeStopLoss() {
            if (this.stopLoss) {
                if (this.buyAtvalue == "Market Order") {
                    if (this.$parent.tradeType == 'buy') {
                        this.stoplossPrice = this.negSLTP()
                    } else if (this.$parent.tradeType == 'sell') {
                        this.stoplossPrice = this.posSLTP()
                    }
                } else if (this.buyAtvalue == "Limit Order") {
                    if (this.$parent.tradeType == 'buy') {
                        this.stoplossPrice = this.negLimitSLTP()
                    } else if (this.$parent.tradeType == 'sell') {
                        this.stoplossPrice = this.posLimitSLTP()
                    }
                }
            }
        },
        changeTakeProfit() {
            if (this.takeProfit) {
                if (this.buyAtvalue == "Market Order") {
                    if (this.$parent.tradeType == 'buy') {
                        this.takeProfitPrice = this.posSLTP()
                    } else if (this.$parent.tradeType == 'sell') {
                        this.takeProfitPrice = this.negSLTP()
                    }
                } else if (this.buyAtvalue == "Limit Order") {
                    if (this.$parent.tradeType == 'buy') {
                        this.takeProfitPrice = this.posLimitSLTP()
                    } else if (this.$parent.tradeType == 'sell') {
                        this.takeProfitPrice = this.negLimitSLTP()
                    }
                }
            }
        },
        reset() {
            this.lots = 0.01
            this.amount = 0
            this.margin = 0
        },
        callConversionStats() {
            let form = {
                "currency": this.$parent.instrumentDetail.symbol,
                "value": this.investmentType == this.COMMON.getBaseCurrencySymbol() ? this.margin : (this.investmentType == 'Lots' ? (isNaN(this.lots) ? 0.01 : this.lots) : this.amount),
                "valueType": (this.investmentType == 'Lots') ? 'LOTS' : (this.investmentType == 'Unit') ? 'AMOUNT' : 'MARGIN'
            }
            this.store.getConverstionStats(form, false, this.store.userSelectedAccount.brokerAccountId).then((response) => {
                if (Object.keys(response).length) {
                    this.message = ''
                    this.notSupportedCurrency = false
                    let a = this.amount <= response.minAmountStep ? response.minAmountStep : this.amount;
                    this.amount = Math.round(a / response.minAmountStep) * response.minAmountStep
                    let b = this.lots <= response.minLotsStep ? response.minLotsStep : this.lots;
                    this.lots = parseFloat(Math.round(b / response.minLotsStep) * response.minLotsStep).toFixed(2)
                    this.lots = isNaN(this.lots) ? 0.01 : this.lots
                    let c = this.margin <= response.minMarginStep ? response.minMarginStep : this.margin;
                    this.margin = parseFloat(Math.round(c / response.minMarginStep) * response.minMarginStep).toFixed(2)
                }
            }).catch((error) => {
                console.log()
                if (error?.response?.data?.code && parseInt(error.response.data.code) == 6971) { //Account cannot trade this currency
                    this.notSupportedCurrency = true
                    this.message = error?.response?.data?.message
                } else {
                    this.notSupportedCurrency = false
                    this.message = ''
                }
            });
        },
        increaseCount(type) {
            if (type == 'LOTS') {
                this.lots = parseFloat(this.lots) + parseFloat(this.store.assetTradingConvesrionData?.minLotsStep)
                this.lots = parseFloat(this.lots).toFixed(2)
            } else if (type == 'UNIT') {
                this.amount = parseFloat(this.amount) + parseFloat(this.store.assetTradingConvesrionData?.minAmountStep)
                this.amount = parseFloat(this.amount)
            } else if (type == 'MARGIN') {
                this.margin = parseFloat(this.margin) + parseFloat(this.store.assetTradingConvesrionData?.minMarginStep)
                this.margin = parseFloat(this.margin).toFixed(2)
            } else if (type == 'STOPLOSS') {
                let result = this.stoplossPrice + this.onePip()
                this.stoplossPrice = parseFloat(result)
            } else if (type == 'TakePROFIT') {
                let result = this.takeProfitPrice + this.onePip()
                this.takeProfitPrice = parseFloat(result)
            } else if (type == 'SPECIFIC-PRICE') {
                let result = this.specificPrice + this.onePip()
                this.specificPrice = parseFloat(result)
            }
        },
        decreaseCount(type) {
            if (type == 'LOTS') {
                if (parseFloat(this.lots) > parseFloat(this.store.assetTradingConvesrionData?.minLotsStep)) {
                    this.lots = parseFloat(this.lots) - parseFloat(this.store.assetTradingConvesrionData?.minLotsStep)
                    this.lots = parseFloat(this.lots).toFixed(2)
                }
            } else if (type == 'UNIT') {
                if (parseFloat(this.amount) > parseFloat(this.store.assetTradingConvesrionData?.minAmountStep)) {
                    this.amount = parseFloat(this.amount) - parseFloat(this.store.assetTradingConvesrionData?.minAmountStep)
                    this.amount = parseFloat(this.amount)
                }
            } else if (type == 'MARGIN') {
                if (parseFloat(this.margin) > parseFloat(this.store.assetTradingConvesrionData?.minMarginStep)) {
                    this.margin = parseFloat(this.margin) - parseFloat(this.store.assetTradingConvesrionData?.minMarginStep)
                    this.margin = parseFloat(this.margin).toFixed(2)
                }
            } else if (type == 'STOPLOSS') {
                let result = this.stoplossPrice - this.onePip()
                this.stoplossPrice = parseFloat(result)
            } else if (type == 'TakePROFIT') {
                let result = this.takeProfitPrice - this.onePip()
                this.takeProfitPrice = parseFloat(result)
            } else if (type == 'SPECIFIC-PRICE') {
                let result = this.specificPrice - this.onePip()
                this.specificPrice = parseFloat(result)
            }
        },
        placeOrder() {
            if (this.validSl && this.validTP && this.validSP && this.correctValue) {
                let form = {
                    "currencyName": this.$parent.instrumentDetail.symbol,
                    "tradeType": this.$parent.tradeType == 'buy' ? "BUY" : "SELL",
                }
                if (this.investmentType == 'Lots') {
                    form['lots'] = parseFloat(this.lots)
                }
                if (this.investmentType == 'Unit') {
                    form['amount'] = parseFloat(this.amount)
                }
                if (this.investmentType == this.COMMON.getBaseCurrencySymbol()) {
                    form['lots'] = this.store.assetTradingConvesrionData?.lots * this.updateStats();
                }
                if (this.buyAtvalue == 'Market Order') {
                    form['requestedPrice'] = 0
                }
                if (this.buyAtvalue == 'Limit Order') {
                    form['requestedPrice'] = this.specificPrice
                }
                if (this.stopLoss) {
                    form['stopValue'] = this.stoplossPrice
                }
                if (this.takeProfit) {
                    form['limitValue'] = this.takeProfitPrice
                }
                if (this.buyAtvalue == 'Market Order') {
                    this.store.placeMarketOrder(form, true, this.store.userSelectedAccount.brokerAccountId, this).then((response) => {
                        if (response === true) {
                            this.orderSucess = true
                            this.store.getUserDashboardData({}, true, '', '10000')
                            this.COMMON.pollingAPIs(1,0,0,'tradesTypeOwn',this.currentInstrumentID)
                        }
                    })
                } else {
                    this.store.placePendingOrder(form, true, this.store.userSelectedAccount.brokerAccountId, this).then((response) => {
                        if (response === true) {
                            this.orderSucess = true
                            //this.$parent.tradeType = 'pending'
                            this.$parent.getCurrecnyTrades()
                            this.COMMON.pollingAPIs(0,1,0,'tradesTypeOwn',this.currentInstrumentID)
                        }
                    })
                }
            } else {
                this.errorMessage()
                this.roundToNearest()
            }
        },
        livePrice() { // return Live Price of instruments
            return this.COMMON.livePriceSymbol(this.$parent.instrumentDetail.symbol, this.$parent.tradeType == "buy" ? 'buy' : 'sell')
        },
        posSLTP() { // Market SL/TP Upperlevel
            let result = parseFloat(this.livePrice()) + (this.static_vars.pipsValue * (1 / this.returnPipMultuplier))
            return parseFloat((result).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        negSLTP() { // Market SL/TP Lowerlevel
            let result = parseFloat(this.livePrice()) - (this.static_vars.pipsValue * (1 / this.returnPipMultuplier))
            return parseFloat((result).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        limitPrice(pip) { // Buy Limit Price Calculate with 5 pips
            let result = parseFloat(this.livePrice()) + (pip * (1 / this.returnPipMultuplier))
            return parseFloat((result).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        negLimitSLTP() { // Limit SL/TP Lowerlevel
            let result = this.specificPrice - (this.static_vars.pipsValue * (1 / this.returnPipMultuplier))
            return parseFloat((result).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        posLimitSLTP() { // Limit SL/TP Upperlevel
            let result = this.specificPrice + (this.static_vars.pipsValue * (1 / this.returnPipMultuplier))
            return parseFloat((result).toFixed(this.COMMON.pipDecimal(this.$parent.instrumentDetail.symbol)))
        },
        onePip() { // Find 1 Pip
            return 1 / this.returnPipMultuplier
        },
        errorMessage() { // error message's on order place
            let price = parseFloat(this.livePrice())
            if (this.$parent.tradeType == "buy") {
                if (this.buyAtvalue == "Market Order") {
                    if (this.stopLoss && (price <= this.stoplossPrice)) {
                        this.toast.error(`Stop Loss should be less than ${price}`);
                    } else if (this.takeProfit && (price >= this.takeProfitPrice)) {
                        this.toast.error(`Take Profit should be greater than ${price}`);
                    }
                } else if (this.buyAtvalue == "Limit Order") {
                    if (this.stopLoss && (this.specificPrice <= this.stoplossPrice)) {
                        this.toast.error(`Stop Loss should be less than ${this.specificPrice}`);
                    } else if (this.takeProfit && (this.specificPrice >= this.takeProfitPrice)) {
                        this.toast.error(`Take Profit should be greater than ${this.specificPrice}`);
                    }
                }
            } else if (this.$parent.tradeType == "sell") {
                if (this.buyAtvalue == "Market Order") {
                    if (this.stopLoss && (price >= this.stoplossPrice)) {
                        this.toast.error(`Stop Loss should be greater than ${price}`);
                    } else if (this.takeProfit && (price <= this.takeProfitPrice)) {
                        this.toast.error(`Take Profit should be less than ${price}`);
                    }
                } else if (this.buyAtvalue == "Limit Order") {
                    if (this.stopLoss && (this.specificPrice >= this.stoplossPrice)) {
                        this.toast.error(`Stop Loss should be greater than ${this.specificPrice}`);
                    } else if (this.takeProfit && (this.specificPrice <= this.takeProfitPrice)) {
                        this.toast.error(`Take Profit should be less than ${this.specificPrice}`);
                    }
                }
            }
        },
        limitOrderWatch() { // Limit order conditions using in watch()
            if (this.buyAtvalue == 'Limit Order') {
                if (this.$parent.tradeType == "buy") {
                    if (this.stopLoss && (parseFloat(this.specificPrice) <= parseFloat(this.stoplossPrice))) {
                        this.validSP = false;
                    } else if (this.takeProfit && (parseFloat(this.specificPrice) >= parseFloat(this.takeProfitPrice))) {
                        this.validSP = false;
                    } else { this.validSP = true; }
                } else if (this.$parent.tradeType == "sell") {
                    if (this.stopLoss && (parseFloat(this.specificPrice) >= parseFloat(this.stoplossPrice))) {
                        this.validSP = false;
                    } else if (this.takeProfit && (parseFloat(this.specificPrice) <= parseFloat(this.takeProfitPrice))) {
                        this.validSP = false;
                    } else { this.validSP = true; }
                }
            }
        },
        stopLossWatch() { // Stop Loss conditions using in watch() 
            if (this.stopLoss) {
                if (this.stoplossPrice) {
                    if (this.buyAtvalue == 'Market Order') {
                        if (this.$parent.tradeType == 'buy' && parseFloat(this.stoplossPrice) > parseFloat(this.livePrice())) {
                            this.validSl = false
                        } else if (this.$parent.tradeType == 'sell' && parseFloat(this.stoplossPrice) < parseFloat(this.livePrice())) {
                            this.validSl = false
                        } else {
                            this.validSl = true
                        }
                    } else {
                        if (this.$parent.tradeType == 'buy') {
                            if (parseFloat(this.stoplossPrice) >= parseFloat(this.specificPrice)) {
                                this.validSl = false
                            } else {
                                this.validSl = true
                            }
                        } else if (this.$parent.tradeType == 'sell') {
                            if (parseFloat(this.stoplossPrice) <= parseFloat(this.specificPrice)) {
                                this.validSl = false
                            } else {
                                this.validSl = true
                            }
                        } else {
                            this.validSl = true
                        }
                    }
                } else {
                    this.validSl = false
                }
            }
        },
        takeProfitWatch() { // Take Profit conditions using in watch()
            if (this.takeProfit) {
                if (this.takeProfitPrice) {
                    if (this.buyAtvalue == 'Market Order') {
                        if (this.$parent.tradeType == 'buy' && parseFloat(this.takeProfitPrice) < parseFloat(this.livePrice())) {
                            this.validTP = false
                        } else if (this.$parent.tradeType == 'sell' && parseFloat(this.takeProfitPrice) > parseFloat(this.livePrice())) {
                            this.validTP = false
                        } else {
                            this.validTP = true
                        }
                    } else {
                        if (this.$parent.tradeType == 'buy') {
                            if (parseFloat(this.takeProfitPrice) <= parseFloat(this.specificPrice)) {
                                this.validTP = false
                            } else {
                                this.validTP = true
                            }
                        } else if (this.$parent.tradeType == 'sell') {
                            if (parseFloat(this.takeProfitPrice) >= parseFloat(this.specificPrice)) {
                                this.validTP = false
                            } else {
                                this.validTP = true
                            }
                        } else {
                            this.validTP = true
                        }
                    }
                } else {
                    this.validTP = false
                }
            }
        },
        roundToNearest() { // Calculate nearest possible value
            let division = this.findDivision()
            if (Object.keys(this.store.assetTradingConvesrionData).length && !this.checkMarketAvailability()) {
                this.correctValue = true
                if (this.investmentType === "Lots") {
                    let step = this.store.assetTradingConvesrionData?.minLotsStep;
                    this.lots = Math.min((division * step).toFixed(2), Math.max(step, this.lots));
                    this.lots = parseFloat(Math.floor(this.lots / step) * step).toFixed(2);
                }
                if (this.investmentType === "Unit") {
                    let step = this.store.assetTradingConvesrionData?.minAmountStep;
                    this.amount = Math.min(division * step, Math.max(step, this.amount));
                    this.amount = Math.floor(this.amount / step) * step;
                }
                let baseCurrencySymbol = this.COMMON.getBaseCurrencySymbol();
                if (this.investmentType === baseCurrencySymbol) {
                    let step = this.store.assetTradingConvesrionData?.minMarginStep;
                    this.margin = Math.min((division * step).toFixed(2), Math.max(step, this.margin));
                    this.margin = parseFloat(Math.floor(this.margin / step) * step).toFixed(2);
                }
            }
        },
        orderError() { // Error if wrong value enter
            let division = this.findDivision();
            if (Object.keys(this.store.assetTradingConvesrionData).length && !this.checkMarketAvailability()) {
                if (this.investmentType === "Lots") {
                    let step = this.store.assetTradingConvesrionData?.minLotsStep;
                    let maxVal = (division * step).toFixed(2);
                    let val = Math.max(step, this.lots);
                    if (step > this.lots) {
                        this.investmentError = `${this.$t('buysell.text19')} ${step}`
                        this.correctValue = false
                    } else if (val > maxVal) {
                        this.investmentError = `${this.$t('buysell.text22')}`
                        this.correctValue = false
                    } else {
                        this.investmentError = ''
                        this.correctValue = true
                    }
                }
                if (this.investmentType === "Unit") {
                    let step = this.store.assetTradingConvesrionData?.minAmountStep;
                    let maxVal = (division * step).toFixed(2);
                    let val = Math.max(step, this.amount);
                    if (step > this.amount) {
                        this.investmentError = `${this.$t('buysell.text20')} ${step}`
                        this.correctValue = false
                    } else if (val > maxVal) {
                        this.investmentError = `${this.$t('buysell.text22')}`
                        this.correctValue = false
                    } else {
                        this.investmentError = ''
                        this.correctValue = true
                    }
                }
                let baseCurrencySymbol = this.COMMON.getBaseCurrencySymbol();
                if (this.investmentType === baseCurrencySymbol) {
                    let step = this.store.assetTradingConvesrionData?.minMarginStep;
                    let maxVal = (division * step).toFixed(2);
                    let val = Math.max(step, this.margin);
                    if (step > this.margin) {
                        this.investmentError = `${this.$t('buysell.text21')} ${step}`
                        this.correctValue = false
                    } else if (val > maxVal) {
                        this.investmentError = `${this.$t('buysell.text22')}`
                        this.correctValue = false
                    } else {
                        this.investmentError = ''
                        this.correctValue = true
                    }
                }
            }
        },
        findDivision() { // Calculate margin step
            return this.store.assetTradingConvesrionData?.availableMargin / this.store.assetTradingConvesrionData?.margin;
        },
        updateStats() {
            let lotMultiplier = ''
            let unitMultiplier = ''
            let currencyMultiplier = ''
            if (this.investmentType === "Lots") {
                lotMultiplier = Math.floor(this.lots / this.store.assetTradingConvesrionData?.minLotsStep)
            } else if (this.investmentType === "Unit") {
                unitMultiplier = Math.floor(this.amount / this.store.assetTradingConvesrionData?.minAmountStep)
            } else if (this.investmentType === this.COMMON.getBaseCurrencySymbol()) {
                currencyMultiplier = Math.floor(this.margin / this.store.assetTradingConvesrionData?.minMarginStep)
            }
            return parseFloat(Math.max(Math.max(lotMultiplier, unitMultiplier, currencyMultiplier), 1))
        },
    },
    mounted() {
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".outside").length) {
                _that.roundToNearest()
                _that.orderError()
            }
        });
    },
    created() {
        if (!Object.keys(this.store.userSelectedAccount).length && this.store.allTypeAccount) {
            let data = {};
            if (this.store.allTypeAccount == 'ALL_LIVE') {
                data = this.store.userTradingAccountsList.find(i => i.demo === false && i.connectivityStatus == 'Connected')
                if (data !== undefined || data !== null) {
                    this.store.$patch({ 'allTypeAccount': null })
                    this.store.$patch({ 'userSelectedAccount': data })
                }
            } else if (this.store.allTypeAccount == 'ALL_DEMO') {
                data = this.store.userTradingAccountsList.find(i => i.demo === true)
                if (data !== undefined || data !== null) {
                    this.store.$patch({ 'allTypeAccount': null })
                    this.store.$patch({ 'userSelectedAccount': data })
                }
            }
            this.store.getUserDashboardData({}, true, '', '10000')
            let form = {
                'targetBrokerAccountId': this.store.userSelectedAccount.brokerAccountId,
                'page': 0,
                'size': 10,
                'sort': 'id,desc',
            };
            this.store.getProviderOpenTrades({}, true);
            this.store.userNotifications(form, false)
        }
        this.callConversionStats()
        this.returnPipMultuplier = this.COMMON.pipMultiplier(this.$parent.instrumentDetail.symbol)
    },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.disabledCustom {
    opacity: 0.5;
}
</style>